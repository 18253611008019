import React from 'react';
import { Box, Container, Grid, Button, Typography, Card, CardContent } from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';
import ThemedHeaderMenu from '../Components/ThemedHeaderMenu';
import HomePageFooter from '../Components/HomePageFooter';

import { useTheme } from "@mui/material/styles";

import EstimateSessionDataService from "../service/EstimateSessionDataService";
import DialogConfirm from "../Components/DialogConfirm/DialogConfirm";
import UserManagementService from "../service/UserManagementService";

const Dashboard = () => {
    const navigate = useNavigate();

    const theme = useTheme();

    const [confirmDeleteAccountOpen, setConfirmDeleteAccountOpen] = React.useState(false);

    // Handler for deleting the user's account
    const handleDeleteAccount = async () => {

        // soft deletes the logged in User
        await EstimateSessionDataService.deleteLoggedInUser();

        // logs out the logged in User by removing the JWT token from sessionStorage
        sessionStorage.removeItem('jwtToken');

        setConfirmDeleteAccountOpen(false);

        // Redirect to home page
        navigate('/');
    };


    return (
        <Grid
            container
            height={1}
            sx={{ bgcolor: theme.palette.secondary.main }}
            overflow="auto"
        >
            {/* Header */}
            <ThemedHeaderMenu themeSwitch title="Dashboard" />

            {/* Content Section */}
            <Container maxWidth="lg" style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Typography variant="h4" gutterBottom>
                    Tools
                </Typography>

                <Grid container spacing={4}>
                    {/* Planning Poker */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Card onClick={() => navigate('/planningPoker')} style={{ cursor: 'pointer' }}>
                            <CardContent>
                                <Typography variant="h5">Planning Poker</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    A tool for team-based effort estimation.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Complexity Buckets Estimate */}
                    <Grid item xs={12} sm={6} md={4}>

                        <>
                            {UserManagementService.isTenantPermanent() ? (
                                <Card onClick={() => navigate('/complexityBuckets')} style={{ cursor: 'pointer' }}>
                                    <CardContent>
                                        <Typography variant="h5">Complexity Buckets Estimate</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            A tool to categorize tasks by complexity.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            ) : (
                                <Card>
                                    <CardContent>
                                        <Typography variant="h5">Complexity Buckets Estimate</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            A tool to categorize tasks by complexity.
                                        </Typography>
                                        <Typography variant="body2" color="error">
                                            Premium Plan Only
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )}
                        </>

                    </Grid>

                    {/* White Elephant Sizing (Coming Soon) */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Card style={{ cursor: 'default' }}>
                            <CardContent>
                                <Typography variant="h5">White Elephant Sizing</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Faster estimation technique compared to Planning Poker.
                                </Typography>
                                <Typography variant="body2" color="error">
                                    Premium Plan Only
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>


                <Typography variant="h4" style={{ marginTop: '40px' }} gutterBottom>
                    Training
                </Typography>


                <Grid container spacing={4}>
                    {/* Estimates in Agile Software Development */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Card onClick={() => navigate('/course/EstimatesInAgileSoftwareDevelopment')} style={{ cursor: 'pointer' }}>
                            <CardContent>
                                <Typography variant="h5">Video Course: Estimates in Agile Software Development</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Learn about tools, processes, and best practices in Agile software development.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* Estimates in Agile Software Development - In person*/}
                    <Grid item xs={12} sm={6} md={4}>
                        <Card onClick={() => navigate('/course/EstimatesInAgileSoftwareDevelopmentInPerson')} style={{ cursor: 'pointer' }}>
                            <CardContent>
                                <Typography variant="h5">In-Person Course: Estimates in Agile Software Development</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Ask for an in-person training on "Estimates in Agile Software Development" delivered to your company.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Typography variant="h4" style={{ marginTop: '40px' }} gutterBottom>
                    Account Management
                </Typography>

                {/* Delete User Account Button */}
                <Button
                    variant="contained"
                    color="success"
                    component={Link}
                    onClick={() => {setConfirmDeleteAccountOpen(true)}}
                >
                    Delete My Account
                </Button>
                <DialogConfirm
                    title={`Are you sure you want to permanently delete your account?`}
                    open={confirmDeleteAccountOpen}
                    onConfirm={() => handleDeleteAccount()}
                    onReject={() => {setConfirmDeleteAccountOpen(false)}}
                    confirmText='YES'
                    rejectText='NO'
                />

            </Container>

            {/* Footer */}
            <HomePageFooter />
        </Grid>
    );
};

export default Dashboard;
